import React, {useEffect, useState} from 'react'
import {useApi} from "../../hooks/useApi";
import Titulo from "../../components/Titulo";

export default function Relatorio() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [dataInicio, setDataInicio] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [relatorioDuasColunas, setRelatorioDuasColunas] = useState([])

    useEffect(() => {
        handleRelatorioDuasColunas()
    }, [])

    async function handleRelatorioDuasColunas() {
        let response = await api.relatorio.DocumentoPorSetor(dataInicio, dataFinal);
        setRelatorioDuasColunas(response.data)
    }

    return (
        <>
            <div className="p-5">
                <div><Titulo>Relatórios</Titulo></div>
                <hr/>

                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Setor</th>
                        <th scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        relatorioDuasColunas.map(({label, id, total}) => (
                            <tr key={id}>
                                <td>{label}</td>
                                <td>{total}</td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>

            </div>
        </>
    )

}