import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFolderOpen,
    faEye, faFilter, faFilterCircleXmark, faKey, faLock, faLockOpen,
    faPenNib,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import './processo.css'
import {filterProcesso} from "../../types/filter_processo";
import withReactContent from "sweetalert2-react-content";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal)
export default function ListarProcesso() {
    let context = useContext(AuthContext)
    const [processos, setProcessos] = useState([])
    const [filter, setFilter] = useState<filterProcesso>()
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    let auth = useContext(AuthContext)
    useEffect(() => {
        handleReadAll();
    }, [context.atualizarListaDocumentos])

    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.processo.readAll();
        setProcessos(response.data);
        setLoading(false)
    }

    function handleView(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/visualizar/${processo_id}`)
    }
    function handleViewTodosDocumentos(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/visualizar-todos-documentos/${processo_id}`)
    }


    function handleAlterarVisualizacao(e: any, processo_id: string) {
        e.preventDefault();
        navigate(`/processo/alterar/${processo_id}`)
    }

    const handleFilter = async (filtro: any, tipo_filtro: string) => {

        setFilter({
            status: tipo_filtro === "status" ? filtro : filter?.status,
            dias_atraso: tipo_filtro === "dias_atraso" ? filtro : filter?.dias_atraso,
        })
    }

    const handleReadWithFilter = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        let response = await api.processo.readWithFilter(filter);
        setProcessos(response.data);
        setLoading(false)
    }

    const handleDestroyProcesso = (e:any, processo_id: any) => {
        e.preventDefault()
        MySwal.fire({
            title: "Deletar Processo nº " + processo_id ,
            text: "Tem certeza que deseja deletar o processo?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            confirmButtonColor: "#005f33",
            cancelButtonColor: "red"
        }).then(async (result) => {
            console.log(result)
            if (result.isConfirmed) {
                const response = await api.processo.destroy(processo_id);
                if (response.status === 200) {
                    modal.timer("Deletar Processo", "Processo deletado com sucesso!", "success")
                    await handleReadAll()
                }

            }

        })
    }

    return (
        <>
            {loading && <Loading/>}
            <div className="p-1 mt-2 mb-5 font-verde">
                <form onSubmit={(e) => handleReadWithFilter(e)}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Status</label>
                            <select className="form-select" aria-label="Default select example"
                                    onChange={(e) => handleFilter(e.target.value, "status")}>
                                <option selected>Selecione</option>
                                <option value="ABERTO">Aberto</option>
                                <option value="EM_ANDAMENTO">Em andamento</option>
                                <option value="CONCLUIDO">Concluido</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <label htmlFor="filterInput" className="form-label">Dias em atraso</label>
                            <input type="number" className="form-control" id="filterInput"
                                   placeholder="Dias em atraso"
                                   onChange={(e) => handleFilter(e.target.value, "dias_atraso")}/>
                        </div>
                        <div className="col-xs-6 col-md-1 ">
                            <label className="form-label text-white">Botão</label>
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="submit"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Filtrar"><FontAwesomeIcon icon={faFilter}/>
                                </button>
                            </div>

                        </div>
                        <div className="col-xs-6 col-md-1 1">
                            <label className="form-label text-white">Botão</label>
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="reset"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Limpar Filtro"><FontAwesomeIcon
                                    icon={faFilterCircleXmark}/>
                                </button>
                            </div>

                        </div>
                    </div>
                </form>
                <hr/>
                <Table className="table table-striped table-hover">
                    <Thead>
                    <Tr>
                        <Th>C</Th>
                        <Th>V</Th>
                        <Th>Nº do Processo</Th>
                        <Th>Descrição</Th>
                        <Th>Criado por</Th>
                        <Th>Status</Th>
                        <Th>Perfil Mínimo</Th>
                        <Th>Setor</Th>
                        <Th className="text-center">Ações</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {
                        processos.map(({
                                           atrasado,
                                           dias_atrasado,
                                           name,
                                           setor,
                                           id,
                                           perfil,
                                           passo_atual,
                                           descricao,
                                           confidencial,
                                           visibilidade,
                                       }) => (
                            <Tr key={id}>
                                <Td>{confidencial === 1 ?
                                    <FontAwesomeIcon color="red" icon={faLock}/> :
                                    <FontAwesomeIcon icon={faLockOpen}/>}</Td>
                                <Td>{visibilidade === 1 ?
                                    <FontAwesomeIcon color="red" icon={faLock}/> :
                                    <FontAwesomeIcon icon={faLockOpen}/>}</Td>
                                <Td>{id}</Td>
                                <Td>{descricao}</Td>
                                <Td>{name}</Td>
                                <Td><div className={`${passo_atual} rounded p-2  text-center m-1`} style={{fontSize: 10}}>{passo_atual}  </div>
                                    {
                                        atrasado &&
                                        <>
                                        <div
                                            className="bg-dark p-2 text-light rounded text-center m-1" style={{fontSize: 10}}> {" "}{atrasado ? `ATRASADO há ${dias_atrasado} dia(s) ` : ""}</div>
                                        </>
                                    }
                                </Td>
                                <Td>{perfil}</Td>
                                <Td>{setor}</Td>
                                <Td className="text-center">
                                    <FontAwesomeIcon className="p-2" onClick={(e) => handleViewTodosDocumentos(e, id)} icon={faFolderOpen}/>
                                    <FontAwesomeIcon className="p-2" onClick={(e) => handleView(e, id)} icon={faEye}/>
                                    <FontAwesomeIcon onClick={(e) => handleAlterarVisualizacao(e, id)}
                                                     className="p-2"
                                                     icon={faPenNib}/>
                                    {
                                        passo_atual === "ABERTO" &&
                                        <FontAwesomeIcon className="p-2" onClick={(e) => handleDestroyProcesso(e, id)} icon={faTrash}/>
                                    }


                                </Td>
                            </Tr>
                        ))
                    }
                    </Tbody>
                </Table>

                <Tooltip id="my-tooltip" className="d-none d-lg-block"/>
            </div>
        </>

    )
}