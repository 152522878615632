import React, {useEffect, useState} from 'react';
import ProcessoForm from "./ProcessoForm";
import {useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../hooks/useApi";
import {Documento} from "../../types/documento";
interface Params {
    processo_id?: string;
}
export default function AlterarProcesso() {
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const { processo_id }: Params = useParams();
    const [processo, setProcesso] = useState<Documento>()
    const [usuarios, setUsuarios] = useState([])

    useEffect(() => {
        //@ts-ignore
        handleReadOne(processo_id)
    }, [])
    async function handleReadOne(processo_id: string) {
        let response = await api.processo.readOne(processo_id);
        setProcesso(response.data)
        setUsuarios(response.data.destinatario_user_id)


    }
    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/processo')
    }
    return(
        <div className="p-5">
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div><h1>Alterar Processo</h1></div>

            </div>
            <hr/>
            <div className="mb-3">
            <span style={{marginRight: "20px"}} >
                        <button className="btn btn-primary btn-verde " onClick={
                            (e) => handleVoltar(e)
                        }>Voltar
                        </button>
                    </span>
            </div>
            <ProcessoForm processo = {processo} usuarios_param = {usuarios}  />
        </div>
    )
}