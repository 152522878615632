import React, {useContext, useEffect, useRef, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useApi} from "../../hooks/useApi";
import parse from "html-react-parser";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
export default function VisualizarTodosDocumentoProcessoImpressao() {
    let {processo_id} = useParams();
    const reportTemplateRef = useRef(null);
    let navigate = useNavigate();
    let auth = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [documentos, setDocumentos] = useState([])

    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            orientation: "p",
            unit: 'px',
        });

        // @ts-ignore
        doc.html(reportTemplateRef.current, {
            margin: [10, 10, 10, 10],
            async callback(doc) {
                await doc.save('document');
            },
        });
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {

        handleDocumentos(processo_id)
    }, [processo_id]);


    const handleDocumentos = async (processo_id: string | undefined) => {
        setLoading(true)
        try {
            let response = await api.processo.montarDocumentosDoProcesso(processo_id);
            // @ts-ignore
            setDocumentos(response.data)
        } catch (error) {

        }

        setLoading(false)
    }

    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }


    return (
        <>
            <div className="p-1" >
            <button className="btn btn-primary" onClick={() => handleGeneratePdf()}>Exportar para PDF</button>
            </div>
            <div ref={reportTemplateRef} className="font-verde p-5" style={{width: 400}}>
                <h4 style={{fontSize: 14}}>Documentos pertencentes ao processo nº {processo_id}</h4>
                <hr/>


                <table id="minhaTabela">
                    {
                        documentos.map(({
                                            id,
                                            tipo_documento_id,
                                            reuniao_id,
                                            remetente_user_id,
                                            empresa_id,
                                            resposta_documento_id,
                                            corpo_texto,
                                            numero_documento,
                                            titulo,
                                            descricao,
                                            data_prazo,
                                            horario_prazo,
                                            protocolo,
                                            visualizacao,
                                            created_at,
                                            updated_at,
                                            transicao_documento_id,
                                            confidencial,
                                            nivel_usuario,
                                            processo_id,
                                            dias,
                                            deleted_at,
                                            remetente_nome
                                        }) => (
                            <tr key={id} className="border-bottom border-danger">

                                <h5 style={{fontSize: 14}}>{titulo}</h5>
                                <h5 style={{fontSize: 12}}><span className="fw-bold">N: </span> {id}</h5>
                                <h5 style={{fontSize: 12}}><span className="fw-bold">Remetente: </span>{remetente_nome}</h5>
                                <p style={{fontSize: 10}}> {parse(corpo_texto)}</p>
                                <p style={{color: 'red', fontSize: 8}}>Fim do Documento</p>
                            </tr>

                        ))

                    }
                </table>
            </div>

        </>
    )
}