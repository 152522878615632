import React, {useEffect, useState} from 'react';
import DocumentoForm from "./DocumentoForm";
import {useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../hooks/useApi";
import {Documento} from "../../types/documento";
interface Params {
    documento_id?: string;
}
export default function ResponderDocumento() {
    let {documento_resposta_id} = useParams();
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);

    const [documento, setDocumento] = useState<Documento>()

    useEffect(() => {
        handleReadOne()
    }, [documento_resposta_id]);
    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }
    function handleView(e: any, documento_id: any) {
        e.preventDefault();
        navigate(`/documento/visualizar/${documento_id}`)
    }
    async function handleReadOne() {
        //@ts-ignore
        let response = await api.documento.readOne(documento_resposta_id);
        setDocumento(response.data.documento)
    }
    return(
        <div className="p-5">
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div><h1>Responder Documento</h1></div>

            </div>
            <hr/>
            <div className="mb-3">
            <span style={{marginRight: "20px"}} >
                        <button className="btn btn-primary btn-verde " onClick={
                            (e) => handleVoltar(e)
                        }>Voltar
                        </button>
                    </span>

                    <>
                        <button className="btn  btn-verde-out-line btn-outline-info"
                                onClick={(e) => handleView(e, documento_resposta_id)}>Esse documento é uma resposta a(o) {documento?.tipo_documento},
                            entitulado {documento?.titulo}, escrito pelo(a) {documento?.remetente_nome}/{documento?.remetente_setor_nome}                        </button>
                    </>

            </div>
            <DocumentoForm documento_resposta_id = {documento_resposta_id} />
        </div>
    )
}