import React, {useContext, useEffect, useRef, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../contexts/Auth/AuthContext";
import {useApi} from "../../hooks/useApi";
import parse from "html-react-parser";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
export default function VisualizarTodosDocumentoProcesso() {
    let {processo_id} = useParams();

    let navigate = useNavigate();
    let auth = useContext(AuthContext)
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [documentos, setDocumentos] = useState([])

    const [loading, setLoading] = useState(false);
    useEffect(() => {

        handleDocumentos(processo_id)
    }, [processo_id]);


    const handleDocumentos = async (processo_id: string | undefined) => {
        setLoading(true)
        try {
            let response = await api.processo.montarDocumentosDoProcesso(processo_id);
            // @ts-ignore
            setDocumentos(response.data)
        } catch (error) {

        }

        setLoading(false)
    }

    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }

    function handleVisualizacaoImpressao(e: any) {
        e.preventDefault();
        navigate(`/processo/visualizar-todos-documentos-impressao/${processo_id}`)
    }

    return (
        <>
            <div className="font-verde p-5">
                <h1>Documentos pertencentes ao processo nº {processo_id}</h1>
                <hr/>
                <button className="btn btn-success" onClick={(e) => handleVisualizacaoImpressao(e)}>Exportar para
                    PDF/Impressão
                </button>
                <hr/>

                <table id="minhaTabela">
                    {
                        documentos.map(({
                                            id,
                                            tipo_documento_id,
                                            reuniao_id,
                                            remetente_user_id,
                                            empresa_id,
                                            resposta_documento_id,
                                            corpo_texto,
                                            numero_documento,
                                            titulo,
                                            descricao,
                                            data_prazo,
                                            horario_prazo,
                                            protocolo,
                                            visualizacao,
                                            created_at,
                                            updated_at,
                                            transicao_documento_id,
                                            confidencial,
                                            nivel_usuario,
                                            processo_id,
                                            dias,
                                            deleted_at,
                                            remetente_nome
                                        }) => (
                            <tr key={id} className="border-bottom border-danger">

                                <h5>{titulo}</h5>
                                <h5><span className="fw-bold">N: </span> {id}</h5>
                                <h5><span className="fw-bold">Remetente: </span>{remetente_nome}
                                </h5>
                                <p> {parse(corpo_texto)}</p>
                                <p style={{color: 'red'}}>Fim do Documento</p>
                            </tr>

                        ))

                    }
                </table>

            </div>

        </>
    )
}