import React, {useContext, useEffect, useState} from "react";
import ContadorTextFiled from "../../components/ContadorTextField";
import {EssaDataNaoPodeEstarNoPassado} from "../../services/DateValidate";
import Loading from "../../components/Loading";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Tooltip} from "react-tooltip";
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import Select from 'react-select'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMicrophone,
faMicrophoneSlash
} from "@fortawesome/free-solid-svg-icons";
import {
    BtnBold,
    Editor,
    EditorProvider,
    createButton,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';
import {AuthContext} from "../../contexts/Auth/AuthContext";

const MySwal = withReactContent(Swal)

function DocumentoForm({documento, documento_resposta_id, setores_param, usuarios_param}: any) {
    let context = useContext(AuthContext)
    console.log(setores_param)
    const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');

    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    const [loading, setLoading] = useState(false);
    const [documentoId, setDocumentoId] = useState('')
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [escolhaDestinatario, setEscolhaDestinatario] = useState("todos")
    const [tipoDocumentoId, setTipoDocumentoId] = useState("");
    // const [reuniao, setReuniao] = useState('');
    //  const [reuniaoId, setReuniaoId] = useState('');
    const [processoId, setProcessoId] = useState('');
    const [destinatario, setDestinatario] = useState('');
    const [destinatarioId, setDestinatarioId] = useState(usuarios_param);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');

    const [dias, setDias] = useState("")
    const [setor, setSetor] = useState(setores_param);
    const [setores, setSetores] = useState([]);
    const [tipoDocumentos, setTipoDocumentos] = useState([])
    const [reunioes, setReunioes] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [confidencial, setConfidencial] = useState(0)
    const [processos, setProcessos] = useState([])

    const [resposta, setResposta] = useState('');
    const [corpoTexto, setCorpoTexto] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState(1);
    const [pergunta, setPergunta] = useState('');

    const [enviarTodos, setEnviarTodos] = useState(false)
    useEffect(() => {
        handleTipoDocumento()
        //  handleReuniao()
        handleProcessos()
        handleSetores()
        handleUsuarios()
    }, [])

    useEffect(() => {

        if (documento != undefined) {
            setDocumentoId(documento.id)
            setTipoDocumentoId(documento.tipo_documento_id);
            // setReuniao(documento.nome_reuniao)
//setReuniaoId(documento.reuniao_id)
            setDias(documento.dias)
            setDestinatarioId(documento.destinatario_user_id)
            setCorpoTexto(documento.corpo_texto)
            setTitulo(documento.titulo)
            setDescricao(documento.descricao)
            setDias(documento.dias)
            setConfidencial(documento.confidencial)
        }
    }, [documento])

    useEffect(() => {
        if (documento != undefined) {
            if (usuarios_param.length > 0) {
                handleSetorDestinatario(usuarios_param, "destinatario");
                setDestinatarioId(usuarios_param)
                setEscolhaDestinatario("usuario")
            } else {
                handleSetorDestinatario(setores_param, "setor");
                setSetor(setores_param)
                console.log(setores_param)
                setEscolhaDestinatario("setor")
            }
        }
    }, [documento, usuarios_param, setores_param])

    useEffect(() => {
        if (documento_resposta_id != undefined) {
            setResposta(documento_resposta_id)
        }
    }, [documento_resposta_id])
    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.documento.update(confidencial, setor, documentoId, tipoDocumentoId, /*reuniaoId,*/ destinatarioId, corpoTexto, titulo, descricao, dias, enviarTodos, processoId);
        if (response.status === 200) {
            modal.timer("Documento", "Salvo com sucesso!", "success");
        }
        console.log(response.status)
        if (response.status === 422) {
            modal.timer("Documento", "Nome do perfil duplicado", "warning")
        }
    }
    const handleSubmitForm = async (e: any) => {

        e.preventDefault();
        const response = await api.documento.insert(confidencial, resposta, setor, tipoDocumentoId, /*reuniaoId,*/ destinatarioId, corpoTexto, titulo, descricao, dias, processoId, enviarTodos);
        console.log(response)
        if (response.status === 200) {
            modal.timer("Documento", "Salvo com sucesso!", "success");
            context.setAtualizarListas(parseInt(String(Math.random() * 20)))
        }
        console.log(response.status)
        if (response.status === 422) {

            modal.timer("Documento", "Nome do perfil duplicado", "warning")
        }
    }

    const handleClearInput = () => {

        setTipoDocumentoId("");
        //  setReuniao('')
        //setReuniaoId('')
        setDestinatario('')
        setResposta('')
        setCorpoTexto('')
        setNumeroDocumento(0)
        setTitulo('')
        setDescricao('')
        setDias('')

    }

    function handleSetorDestinatario(SetorDestinatario: string, escolha: string) {
        if (escolha === "setor") {
            setSetor(SetorDestinatario)
            setDestinatarioId('')
        }

        if (escolha === "destinatario") {
            setSetor('')
            setDestinatarioId(SetorDestinatario)
        }

    }


    const handleUsuarios = async () => {
        setLoading(true)
        let response = await api.usuario.usuarioComLabelValue();
        console.log(response.data)
        setUsuarios(response.data);
        setLoading(false)
    }
    const handleTipoDocumento = async () => {
        setLoading(true)
        let response = await api.tipo_documento.readAll();
        setTipoDocumentos(response.data);
        setLoading(false)
    }
    const handleProcessos = async () => {
        setLoading(true)
        let response = await api.processo.processoParaDocumentos();
        setProcessos(response.data);
        setLoading(false)
    }

    const handleSetores = async () => {
        setLoading(true)
        let response = await api.setor.setorComValueLabel();
        setSetores(response.data);
        setLoading(false)
    }


    async function handleIa(e: any) {

        const swalConfirm = MySwal.fire({
            title: 'Solicitação para a IA',
            text: "Caso faça a solicitação, substituirá o corpo do texto, tem certeza que deseja continuar?",
            icon: "warning",
            confirmButtonText: "Continuar",
            showConfirmButton: true,
            showCancelButton: true,
        })

        swalConfirm.then(async (result) => {
            //@ts-ignore
            if (result.isConfirmed) {
                const swalLoading = MySwal.fire({
                    title: 'A IA está construindo o texto solicitado...',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                Swal.showLoading();
                let response = await api.IA2.teste(pergunta);
                setCorpoTexto(response.candidates[0].content.parts[0].text)
                console.log(response)
                if (response) {
                    modal.timer('Sucesso', 'A ação foi concluída com sucesso.', 'success')
                } else {
                    modal.timer('Erro', 'Ocorreu um erro ao executar a ação.', 'error')
                }
            }
        });

    }

    async function handleAnaliseSemantica(e: any) {

        const swalConfirm = MySwal.fire({
            title: 'Semantica do Texto',
            text: "Será feita uma análise do sentido do texto, explicando a proposta que está no corpo do texto.",
            icon: "warning",
            confirmButtonText: "Continuar",
            showConfirmButton: true,
            showCancelButton: true,
        })

        swalConfirm.then(async (result) => {
            //@ts-ignore
            if (result.isConfirmed) {
                const swalLoading = MySwal.fire({
                    title: 'A IA está construindo o texto solicitado...',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                Swal.showLoading();
                let response = await api.IA2.teste(`Faça uma análise do sentido desse texto: ${corpoTexto}`);
                console.log(response)
                if (response) {
                    MySwal.fire({
                        text: response.candidates[0].content.parts[0].text
                    })
                } else {
                    modal.timer('Erro', 'Ocorreu um erro ao executar a ação.', 'error')
                }
            }
        });
    }

    async function handleCorreçãoOrtográfica(e: any) {

        const swalConfirm = MySwal.fire({
            title: 'Correção Gramatical e Ortográfica',
            text: "Caso faça a correção gramatical e ortográfica, substituirá o corpo do texto, tem certeza que deseja continuar?",
            icon: "warning",
            confirmButtonText: "Continuar",
            showConfirmButton: true,
            showCancelButton: true,
        })

        swalConfirm.then(async (result) => {
            //@ts-ignore
            if (result.isConfirmed) {
                const swalLoading = MySwal.fire({
                    title: 'A IA está construindo o texto solicitado...',
                    icon: 'info',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                Swal.showLoading();
                let response = await api.IA2.teste(`Faça a correção ortográfica e gramatical desse texto: ${corpoTexto}`);
                setCorpoTexto(response.candidates[0].content.parts[0].text)
                console.log(response)
                if (response) {
                    modal.timer('Sucesso', 'A ação foi concluída com sucesso.', 'success')
                } else {
                    modal.timer('Erro', 'Ocorreu um erro ao executar a ação.', 'error')
                }
            }
        });
    }


    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    useEffect(() => {
        setPergunta(transcript)
    }, [transcript]);
    const startListening = () => {
        SpeechRecognition.startListening({language: 'pt-BR'});
    };
    const handleReactSelectUsuario = (selectedOption: any) => {
        setDestinatarioId(selectedOption);
    };
    const handleReactSelectSetor = (selectedOption: any) => {
        setSetor(selectedOption);
    };
    const handleChangeCorpoTexto = (e: any) => {
        setCorpoTexto(e.target.value);
    };
    useEffect(() => {
        if (escolhaDestinatario === 'todos') {
            setEnviarTodos(true)
        } else {
            setEnviarTodos(false)
        }
    }, [escolhaDestinatario]);
    return (
        <>
            {loading && <Loading/>}


            <form onSubmit={documentoId === '' ? (e) => handleSubmitForm(e) : (e) => handleSubmitOne(e)}>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="form-floating">
                            <input type="text" className="form-control" id="floatingInput"
                                   value={titulo}
                                   data-tooltip-id="my-tooltip"
                                   data-tooltip-content="Título do documento para listagem."
                                   maxLength={255}
                                   onChange={(e) => setTitulo(e.target.value)}
                                   placeholder="Título" required/>
                            <label htmlFor="floatingInput">Título</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-floating">
                            <select id="floatingInput" value={dias} className="form-select"

                                    onChange={(e) => setDias(e.target.value)}
                                    required={true}
                                    data-tooltip-id="my-tooltip" data-tooltip-content="Dias de Prazo"
                            >

                                <option value="">Selecione</option>
                                <option value={0}>Não há prazo</option>
                                <option value={1}>1 dia</option>
                                <option value={3}>3 dias</option>
                                <option value={7}>1 semana</option>
                                <option value={14}>2 semanas</option>
                                <option value={30}>1 mês</option>

                            </select>
                            <label htmlFor="floatingInput">Prazo para resposta (Dias Úteis)</label>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-3">
                        <div className="form-floating">
                            <select id="floatingInput" value={tipoDocumentoId} className="form-select"

                                    onChange={(e) => setTipoDocumentoId(e.target.value)}
                                    required={true}
                                    data-tooltip-id="my-tooltip" data-tooltip-content="Tipo de Documento."
                            >

                                <option value="">Selecione</option>
                                {tipoDocumentos.map(({id, grupo_id, descricao}) => (
                                    <option key={id} value={id}>{descricao}</option>
                                ))}
                            </select>
                            <label htmlFor="floatingInput">Tipo de Documento</label>
                        </div>
                    </div>

                    {/*<div className="col-md-3">
                        <div className="form-floating">
                            <select id="floatingInput" value={reuniaoId} className="form-select"
                                    data-tooltip-id="my-tooltip" data-tooltip-content="Nome da Reunião"
                                    onChange={(e) => setReuniaoId(e.target.value)} required
                            >
                                <option selected value="">Selecione</option>
                                {reunioes.map(({id, descricao}) => (
                                    <option key={id} value={id}>{descricao}</option>
                                ))}
                            </select>
                            <label htmlFor="floatingInput">Reunião</label>
                        </div>
                    </div> */}
                    {
                        processos.length > 0 &&
                        <div className="col-md-3">
                            <div className="form-floating">
                                <select id="floatingInput" value={processoId} className="form-select"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Vincular documento ao processo"
                                        onChange={(e) => setProcessoId(e.target.value)}
                                >
                                    <option selected value="">Selecione</option>
                                    {processos.map(({id, descricao}) => (
                                        <option key={id} value={id}>{id} - {descricao}</option>
                                    ))}
                                </select>
                                <label htmlFor="floatingInput">Processo</label>
                            </div>
                        </div>
                    }


                </div>
                <hr/>
                <div className="row mb-3">
                    <h4 data-tooltip-id="my-tooltip"
                        data-tooltip-content="Público: Você, seu superior direto e o destinatário podem ver. Privado: Somente você e o desetinatário podem ver.">Confidencialidade
                        do Documento</h4>

                    <div className="col-md-3">
                        <div className="form-check" onClick={(e) => setConfidencial(0)}>
                            <input className="form-check-input" type="radio" name="confidencial"
                                   id="confidencial1" value="0" required={true}
                                   checked={confidencial === 0 ? true : false}/>
                            <label className="form-check-label" htmlFor="confidencial1">
                                Público
                            </label>
                        </div>

                    </div>
                    <div className="col-md-3">
                        <div className="form-check" onClick={(e) => setConfidencial(1)}>
                            <input className="form-check-input" type="radio" name="confidencial"
                                   id="confidencial2" value="1"
                                   checked={confidencial === 1 ? true : false}/>
                            <label className="form-check-label" htmlFor="confidencial2">
                                Privado
                            </label>
                        </div>

                    </div>
                </div>
                <hr/>
                <div className="row">
                    <h4>Destinatário</h4>
                    <div className="row">

                        <div className="col-md-3">
                            <div className="form-check" onClick={(e) => setEscolhaDestinatario('usuario')}>
                                <input className="form-check-input" type="radio" name="exampleRadios"
                                       id="exampleRadios2" value="option2" required={true}
                                       checked={escolhaDestinatario === 'usuario' ? true : false}/>
                                <label className="form-check-label" htmlFor="exampleRadios2">
                                    Enviar para usuários
                                </label>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div className="form-check" onClick={(e) => setEscolhaDestinatario('setor')}>
                                <input className="form-check-input" type="radio" name="exampleRadios"
                                       id="exampleRadios1" value="option1"
                                       checked={escolhaDestinatario === 'setor' ? true : false}/>
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    Enviar para setores
                                </label>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <div className="form-check" onClick={(e) => setEscolhaDestinatario('todos')}>
                                <input className="form-check-input" type="radio" name="exampleRadios"
                                       id="exampleRadios3" value="option1"
                                       checked={escolhaDestinatario === 'todos' ? true : false}/>
                                <label className="form-check-label" htmlFor="exampleRadios3">
                                    Enviar para todos
                                </label>
                            </div>

                        </div>
                    </div>
                    {
                        escolhaDestinatario === "setor" &&
                        <div className="col mt-4">
                            <p className="text-danger fw-bold">Todos os funcionários desse setor poderão ver
                                esse documento! </p>
                            <div className="form-floating">
                                <Select isMulti defaultValue={setor} onChange={handleReactSelectSetor}
                                        placeholder={<div>Setores</div>} options={setores}/>
                            </div>
                        </div>
                    }

                    {
                        escolhaDestinatario === "usuario" &&
                        <div className="col mt-4">
                            <p className="text-danger fw-bold">Somente o usuário selecionado poderá ver esse
                                documento! </p>
                            <div className="form-floating">
                                <Select isMulti defaultValue={destinatarioId}
                                        onChange={handleReactSelectUsuario} placeholder={<div>Usuários</div>}
                                        options={usuarios}/>

                            </div>
                        </div>
                    }


                </div>
                <hr/>
                <div className="col  mt-3">
                    <h4>Solicitações a IA</h4>
                    <div>
                        <p>Microfone: {listening ? 'Ligado' : 'Desligado'}</p>

                        {          //@ts-ignore
                            <button type="button" className="btn btn-info m-2 mb-3" onClick={startListening}><FontAwesomeIcon icon={faMicrophone} /></button>}

                            <button type="button"  className="btn btn-info m-2  mb-3    " onClick={SpeechRecognition.stopListening}><FontAwesomeIcon icon={faMicrophoneSlash} /></button>
                    </div>
                    <div className="form-floating">
                        <textarea className="form-control" value={pergunta}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  maxLength={1000}
                                  style={{height:100}}
                                  onChange={(e) => setPergunta(e.target.value)}
                        ></textarea>
                        <label htmlFor="floatingTextarea2" >Descreva o documento </label>
                        <ContadorTextFiled texto={pergunta} tamanho={1000}/>
                        <button className="btn btn-verde btn-primary btn-block  w-100"
                                onClick={(e) => handleIa(e)}>Produzir texto com Inteligência Artificial
                        </button>
                    </div>
                </div>
                <div className="col  mt-3">
                    <div className="form-floating">
                        <EditorProvider>
                            <Editor value={corpoTexto} onChange={handleChangeCorpoTexto} style={{  height: 300,}}>
                                <Toolbar>
                                    <BtnUndo/>
                                    <BtnRedo/>
                                    <Separator/>
                                    <BtnBold/>
                                    <BtnItalic/>
                                    <BtnUnderline/>
                                    <BtnStrikeThrough/>
                                    <Separator/>
                                    <BtnNumberedList/>
                                    <BtnBulletList/>
                                    <Separator/>
                                    <BtnLink/>
                                    <BtnClearFormatting/>
                                    <HtmlButton/>
                                    <Separator/>
                                    <BtnStyles/>
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        {/*<textarea data-tooltip-id="my-tooltip" data-tooltip-content="Conteúdo do Documento"
                                  className="form-control corpo_texto" value={corpoTexto}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  required
                                  maxLength={5000}
                                  onChange={(e) => setCorpoTexto(e.target.value)}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Corpo do Texto</label> */}
                        <ContadorTextFiled texto={corpoTexto} tamanho={5000}/>
                    </div>


                </div>
                <div className="row mt-2 ">
                    <div className="col">
                        <button className="btn btn-verde btn-primary  btn-block  w-100"
                                onClick={(e) => handleCorreçãoOrtográfica(e)}>Correção Ortográfica e Gramatical
                        </button>
                    </div>
                    <div className="col mt-3 mt-md-0">
                        <button className="btn btn-verde btn-primary  w-100"
                                onClick={(e) => handleAnaliseSemantica(e)}>Análise Semântica
                        </button>
                    </div>
                </div>
                <div className="col mt-4">
                    <div className="form-floating">
                        <textarea className="form-control " value={descricao}
                                  placeholder="Leave a comment here"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Descreva em poucas palavras o objetivo deste documento."
                                  id="floatingTextarea2"
                                  maxLength={500}

                                  required
                                  onChange={(e) => setDescricao(e.target.value)}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Descrição</label>
                        <ContadorTextFiled texto={descricao} tamanho={500}/>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <button type="submit"
                                className="btn btn-verde btn-primary btn-block mt-4 w-100">{documentoId === '' ? "Salvar" : 'Atualizar'}</button>
                    </div>
                    <div className="col-md-6">
                        <button type="button" onClick={handleClearInput}
                                className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                        </button>
                    </div>
                </div>
            </form>
            <Tooltip id="my-tooltip" className="d-none d-lg-block"/>
        </>
    )
}

export default DocumentoForm;