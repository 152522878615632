import React, {useEffect, useState} from "react";
import {useApi} from "../../../hooks/useApi";
import {useModal} from "../../../hooks/useModal";
import {IMaskInput} from 'react-imask';
import "./usuario.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faEye, faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import {Perfil} from "../../../types/perfil";
import {EssaDataNaoPodeEstarNoPassado, EssaDataNaoPodeSerMaiorQueEssa} from "../../../services/DateValidate";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Titulo from "../../../components/Titulo";
function UsuarioPage() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();

    const [usuarioId, setUsuarioId] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [perfil, setPerfil] = useState("");
    const [tratamento, setTratamento] = useState('');
    const [inicioMandato, setInicioMandato] = useState('');
    const [fimMandato, setFimMandato] = useState('');
    const [aniversario, setAniversario] = useState('');
    const [telefoneComercial, setTelefoneComercial] = useState('');
    const [telefoneResidencial, setTelefoneResidencial] = useState('');
    const [telefoneCelular, setTelefoneCelular] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [usuarios, setUsuarios] = useState([])
    const [perfis, setPerfis] = useState([])
    const [setores, setSetores] = useState([])
    const [setor, setSetor] = useState('')
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        handleReadAll();
        handlePerfis();
        handleSetores()
    }, [])

    useEffect(() => {
      if(!EssaDataNaoPodeSerMaiorQueEssa(inicioMandato, fimMandato)) {
          modal.semTimerWarning("Data", "A data do Início do Mandato não pode ser posterior a data de Fim de Mandato");
          setFimMandato("")
          setInicioMandato("")
      }

    },[inicioMandato, fimMandato])

    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.usuario.readAll();
        setUsuarios(response.data);
        setLoading(false)
    }

    const handlePerfis = async () => {
        let response = await api.perfil.readAll();
        setPerfis(response.data);
    }

    const handleSetores = async () => {
        setLoading(true)
        let response = await api.setor.readAll();

        setSetores(response.data);
        setLoading(false)
    }

    const handleReadOne = async (e: any, perfil_id: number) => {
        e.preventDefault();
        let response = await api.usuario.readOne(perfil_id);
        setUsuarioId(response.data.id)

        setName(response.data.name)
        setEmail(response.data.email)
        setPerfil(response.data.perfil_id)
        setTratamento(response.data.tratamento)
        setSetor(response.data.setor_id)
        setInicioMandato(response.data.inicio_mandato)
        setFimMandato(response.data.fim_mandato)
        setAniversario(response.data.aniversario)
        setTelefoneComercial(response.data.telefone_comercial)
        setTelefoneResidencial(response.data.telefone_residencial)
        setTelefoneCelular(response.data.telefone_celular)
    }

    const handleSubmitFormPerfil = async (e: any) => {

        e.preventDefault();


        const response = await api.usuario.insert(setor, name, email, perfil, tratamento,
            inicioMandato, fimMandato, aniversario, telefoneComercial, telefoneResidencial, telefoneCelular, senha);
        if (response.status === 200) {
            modal.timer("Usuário", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {

            modal.timer("Usuário", "Nome do perfil duplicado", "warning")
        }
    }
    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.usuario.update(setor, usuarioId, name, email, perfil, tratamento,
            inicioMandato, fimMandato, aniversario, telefoneComercial, telefoneResidencial, telefoneCelular, senha);
        if (response.status === 200) {
            modal.timer("Usuário", "Salvo com sucesso!", "success");
            handleReadAll();
        }

    }

    function handleFimMandato(data: string) {

        if(!EssaDataNaoPodeEstarNoPassado(data)) {
            setFimMandato(data)
        } else {
            modal.semTimerWarning("Final do Mandato", "Essa data não pode estar no passado");
            setFimMandato("")
        }

    }

    function handleAniversario(data: string) {

        if(EssaDataNaoPodeEstarNoPassado(data)) {
            setAniversario(data)
        } else {
            modal.semTimerWarning("Aniversário", "A data de aniversário não pode estar no futuro!");
            setAniversario("")
        }

    }
    const handleSenhaConfirmacao = () => {

        if (senha != confirmarSenha) {
            return false;
        }

        return true;
    }
    const handleDelete = async (e: any, usuario_id: number) => {
        e.preventDefault();

        const response = await api.usuario.delete(usuario_id);

        if (response.status === 200) {
            modal.timer("Usuário", "Deletado com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Usuário", "Nome do perfil duplicado", "warning")
        }
    }


    return (
        <div  className="p-5">
            {loading && <Loading />}

            <div>
                <Titulo>Usuário</Titulo>
                <hr/>
                <form id="formUsuario"
                      onSubmit={usuarioId === 0 ? (e) => handleSubmitFormPerfil(e) : (e) => handleSubmitOne(e)}>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingInput"
                                       value={name}
                                       maxLength={255}
                                       onChange={(e) => setName(e.target.value)}
                                       placeholder="Nome"/>
                                <label htmlFor="floatingInput">Nome Completo</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input type="email" className="form-control" id="floatingInput"
                                       value={email}
                                       maxLength={255}
                                       onChange={(e) => setEmail(e.target.value)}
                                       onBlur={async (event: any) =>  {
                                           console.log(senha, event.target.value)
                                           const response = await api.usuario.verificar_email(event.target.value);
                                           //@ts-ignore
                                           if (response.data > 0) {
                                               return event.target.setCustomValidity(
                                                   `E-mail já cadastrado`,
                                               )
                                           }
                                           return event.target.setCustomValidity("")
                                       }}
                                       placeholder="E-mail"/>
                                <label htmlFor="floatingInput">E-mail</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-floating">
                                <select id="floatingInput" value={perfil} className="form-select"
                                        onChange={(e) => setPerfil(e.target.value)}
                                        required
                                >
                                    <option value="">Selecione</option>
                                    {
                                        perfis.map(({nome_perfil, id}) => (
                                            <option key={id} value={id}>{nome_perfil}</option>
                                        ))
                                    }

                                </select>
                                <label htmlFor="floatingInput">Perfil</label>
                            </div>
                        </div>
                        <div className="col-md-2 mb-4">
                            <div className="form-floating">
                                <select id="floatingInput" value={setor} className="form-select"
                                        onChange={(e) => setSetor(e.target.value)}
                                        required
                                >
                                    <option value="">Selecione</option>
                                    {
                                        setores.map(({descricao, id}) => (
                                            <option key={id} value={id}>{descricao}</option>
                                        ))
                                    }
                                </select>
                                <label htmlFor="floatingInput">Setor</label>
                            </div>
                        </div>
                        <div className="col-md-2 mb-4">
                            <div className="form-floating">
                                <select id="floatingInput" value={tratamento} className="form-select"
                                        onChange={(e) => setTratamento(e.target.value)}
                                        required
                                >
                                    <option value="">Selecione</option>
                                    <option value="Sr./Sra.">Sr./Sra.</option>
                                    <option value="Vossa Excelência">Vossa Excelência</option>
                                </select>
                                <label htmlFor="floatingInput">Tratamento</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating">
                                <input type="password" className="form-control" id="floatingInput"
                                       value={senha}
                                       onChange={(e) => setSenha(e.target.value)}
                                       placeholder="Nome"
                                      />

                                <label htmlFor="floatingInput">Senha</label>
                            </div>
                            {handleSenhaConfirmacao() ? "" :
                                <span className="validacao_mensagem">A senha e a confirmação de senha não conferem</span>}
                        </div>

                        <div className="col-md-3">
                            <div className="form-floating">
                                <input type="password" className="form-control" id="floatingInput"
                                       value={confirmarSenha}
                                       onChange={(e) => setConfirmarSenha(e.target.value)}
                                       placeholder="E-mail"
                                       onInput={(event: any) => {
                                           console.log(senha, event.target.value)
                                           if (senha != event.target.value) {
                                               return event.target.setCustomValidity(
                                                   `A senha e a confirmação de senha não conferem`,
                                               )
                                           }
                                           return event.target.setCustomValidity("")
                                       }}/>
                                <label htmlFor="floatingInput">Confirmar Senha</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                            <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={inicioMandato}
                                       onChange={(e) => setInicioMandato(e.target.value)}
                                       placeholder="Nome"/>
                                <label htmlFor="floatingInput">Início do Mandato</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={fimMandato}
                                       onChange={(e) => handleFimMandato(e.target.value)}
                                       placeholder="E-mail"/>
                                <label htmlFor="floatingInput">Fim do Mandato</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={aniversario}
                                       required
                                       onChange={(e) => handleAniversario(e.target.value)}
                                       placeholder="Nome"/>
                                <label htmlFor="floatingInput">Aniversário</label>
                            </div>
                        </div>

                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                            <div className="form-floating">
                                <IMaskInput className="form-control" id="floatingInput"
                                            mask="(00) 0 0000-0000"
                                            onAccept={(unmaskedValue) => {
                                                setTelefoneCelular(unmaskedValue);
                                            }}
                                            value={telefoneCelular}
                                            required
                                            placeholder="Telefone Residencial"
                                />
                                <label htmlFor="floatingInput">Celular</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating">
                                <IMaskInput className="form-control" id="floatingInput"
                                            mask="(00) 0 0000-0000"
                                            onAccept={(unmaskedValue) => {
                                                setTelefoneComercial(unmaskedValue);
                                            }}
                                            value={telefoneComercial}
                                            placeholder="Telefone Residencial"
                                />
                                <label htmlFor="floatingInput">Telefone Comercial</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating">
                                <IMaskInput className="form-control" id="floatingInput"
                                            mask="(00) 0 0000-0000" value={telefoneResidencial}
                                            onAccept={(unmaskedValue) => {
                                                setTelefoneResidencial(unmaskedValue);
                                            }}
                                            placeholder="Telefone Residencial"
                                />
                                <label htmlFor="floatingInput">Telefone Residencial</label>
                            </div>
                        </div>

                    </div>
                    <div className="row mb-2">

                    </div>

                    <div className="row">

                        <div className="col-md-6">
                            <button type="submit"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">{usuarioId === 0 ? "Salvar" : 'Atualizar'}</button>
                        </div>
                        <div className="col-md-6">
                            <button type="reset"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <hr/>
            <div className="p-1 mt-2 mb-5 ">
                <div className='d-flex  justify-content-end w-100 align-content-end mb-3'>
                    <button className="btn btn-verde btn-primary " onClick={
                        handleReadAll
                    }><FontAwesomeIcon icon={faArrowsRotate}/>
                    </button>
                </div>
                <Table className="table table-striped table-hover">
                    <Thead>
                    <Tr>
                        <Th>Nome</Th>
                        <Th className="text-center">Email</Th>
                        <Th>Telefone Celular</Th>
                        <Th>Perfil</Th>
                        <Th className="text-center">Ações</Th>
                    </Tr>
                    </Thead>
                    <Tbody>

                    {usuarios.map(({id, name, email, telefone_celular, nome_perfil}) => (
                        <Tr key={id}>
                            <Td>{name}</Td>
                            <Td className="text-center">{email}</Td>
                            <Td>{telefone_celular}</Td>
                            <Td>{nome_perfil}</Td>
                            <Td className="text-center"><FontAwesomeIcon onClick={(e) => handleReadOne(e, id)}
                                                                         icon={faPenNib}/> <FontAwesomeIcon
                                onClick={(e) => handleDelete(e, id)} icon={faTrash}/>
                            </Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
            </div>
        </div>
    )
}

export default UsuarioPage;
