import React, {useEffect, useState} from "react";
import {useApi} from "../../hooks/useApi";
import {useModal} from "../../hooks/useModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import Titulo from "../../components/Titulo";

function TipoDocumento() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();

    const [tipoDocumentoId, setTipoDocumentoId] = useState(0);

    const [descricao, setDescricao] = useState("");
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [passo, setPasso] = useState('')
    const [passos, setPassos] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleReadAll();
        handleReadAllTransacoes()
    }, [])
    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.tipo_documento.readAll();
        setTiposDocumentos(response.data);
        setLoading(false)
    }
    const handleReadAllTransacoes = async () => {
        setLoading(true)
        let response = await api.documento.grupos_documento();
        setPassos(response.data);
        setLoading(false)
    }
    const handleReadOne = async (e: any, tipo_documento_id: number) => {
        e.preventDefault();
        let response = await api.tipo_documento.readOne(tipo_documento_id);
        setDescricao(response.data.descricao)
        setTipoDocumentoId(response.data.id)

    }

    const handleSubmitForm = async (e: any) => {

        e.preventDefault();
        const response = await api.tipo_documento.insert(descricao, passo);
        if (response.status === 200) {
            modal.timer("Tipo de Documento", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Tipo de Documento", "Nome do Tipo de Documento duplicado", "warning")
        }
    }
    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.tipo_documento.update(tipoDocumentoId, descricao, passo);
        if (response.status === 200) {
            modal.timer("Tipo de Documento", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Tipo de Documento", "Nome do perfil duplicado", "warning")
        }
    }

    const handleDelete = async (e: any, tipo_documento_id: number) => {
        e.preventDefault();

        const response = await api.tipo_documento.delete(tipo_documento_id);

        if (response.status === 200) {
            modal.timer("Tipo de Documento", "Deletado com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Tipo de Documento", "Nome do perfil duplicado", "warning")
        }
    }



    return(
        <div className="p-5">
            {loading && <Loading />}
            <div>
                <Titulo>Tipo de Documento</Titulo>
                {/*
                <form onSubmit={tipoDocumentoId === 0 ? (e) => handleSubmitForm(e) : (e) => handleSubmitOne(e)}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingInput"
                                       value={descricao}
                                       maxLength={25}
                                       onChange={(e) => setDescricao(e.target.value)}
                                       placeholder="Nome" required/>
                                <label htmlFor="floatingInput">Nome</label>
                            </div>
                        </div>

                    <div className="col">
                        <div className="form-floating">
                            <select id="floatingInput" value={passo} className="form-select"
                                    onChange={(e) => setPasso(e.target.value)}
                                    required
                            >
                                <option value="">Selecione</option>
                                {
                                    passos.map(({descricao, id}) => (
                                        <option key={id} value={id}>{descricao}</option>
                                    ))
                                }


                            </select>
                            <label htmlFor="floatingInput">Grupo</label>
                        </div>
                    </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6">
                            <button type="submit"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">{tipoDocumentoId === 0 ? "Salvar" : 'Atualizar'}</button>
                        </div>
                        <div className="col-md-6">
                            <button type="reset"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                            </button>
                        </div>
                    </div>
                </form>
                */
                }

            </div>

            <div className="p-1 mt-2 mb-5 ">
                <div className='d-flex  justify-content-end w-100 align-content-end mb-3'>
                    <button className="btn btn-verde btn-primary " onClick={
                        handleReadAll
                    }><FontAwesomeIcon icon={faArrowsRotate}/>
                    </button>
                </div>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Descrição</th>
                        <th>Grupo</th>
                    </tr>
                    </thead>
                    <tbody>

                    {tiposDocumentos.map(({id, descricao, grupo}) => (
                        <tr key={id}>
                            <td>{descricao}</td>
                            <td>{grupo}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TipoDocumento;