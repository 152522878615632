import React from "react";
function Titulo({children}:any) {

    return(
        <>
            <h1 className="text-center d-block d-sm-none">{children}</h1>
            <h1 className="d-none d-sm-block">{children}</h1>
        </>
    )
}

export default Titulo;