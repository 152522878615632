import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {useApi} from "../../hooks/useApi";
import ListarDocumento from "./ListarDocumento";
import VisualizarDocumento from "./VisualizarDocumento";

export default function HistoricoDocumento({documento_id}: any) {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const [documentosHistorico, setDocumentosHistorico] = useState([])

    useEffect(() => {
        handleHistorico(documento_id)
    }, [documento_id]);
    async function handleHistorico(documento_id: string) {
        const response = await api.documento.pegarHistoricoPorDocumento(documento_id);

        setDocumentosHistorico(response.data)
    }
    function handleViewMudancas(e:any,  documento_id: string) {

    }

    return (
        <>
            <h2 className="text-center mt-5 mb-3 fw-bold">Histórico de Mudanças</h2>
            <div className="        mt-2 mb-5 ">
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Nº do Registro</th>
                        <th>Título</th>
                        <th>Criado em</th>
                        {/*<th className="text-center">Ações</th>*/}
                    </tr>
                    </thead>
                    <tbody>

                    {documentosHistorico.map(({id, titulo, created_at, documento_id}) => (
                        <tr key={id}>
                            <td>{id}</td>
                            <td>{titulo}</td>
                            <td>{created_at}</td>
                            {/*<td className="text-center">
                                <FontAwesomeIcon onClick={(e) => handleViewMudancas(e, id)}
                                                 className="p-2"
                                                 icon={faEye}/>

                            </td> */}
                        </tr>
                    ))}

                    </tbody>
                </table>
                {documentosHistorico.length == 0 &&
                    <div className="fw-bold text-center">Não há modificações</div>}
            </div>
        </>
    )
}